﻿// ReSharper disable once UseOfImplicitGlobalInFunctionScope
(function ($) {

    $.fn.autocompleteSearcher = function (options) {
        if (!jQuery().selectpicker) throw "Plugin Select Picker (bootstrap-select de Silvio Moreto https://developer.snapappointments.com/bootstrap-select/) versión 1.13.1 o superior es requerido.";
        if (!jQuery().ajaxSelectPicker) throw "Plugin Ajax Select Picker (https://github.com/truckingsim/Ajax-Bootstrap-Select) versión 1.4.3 o superior es requerido.";

        var defaults = {
            UrlQuery: "",
            CustomHeaders: {},
            processData: function (data) {}
        }

        var settings = $.extend({}, defaults, options);

        $(this).selectpicker().ajaxSelectPicker({
            ajax: {
                url: settings.UrlQuery,
                headers: settings.CustomHeaders
            },
            preprocessData: settings.processData
        });

        $(this).on("hidden.bs.select", function (e) {
            var _items = $(this).parent().find("ul.dropdown-menu > li").not(".dropdown-header.optgroup-1").not(".optgroup-1.selected").not(".divider.optgroup-1div").not(".dropdown-menu > .status");
            $(this).parent().find(".dropdown-menu.open").removeAttr("style");
            $(this).parent().find(".dropdown-menu.open > .inner.open").removeAttr("style");
            $(_items).remove();
            $(this).parent().find(".dropdown-menu > .status").text("Escriba para buscar");
            $(this).parent().find(".dropdown-menu > .status").show();
        });

        $(this).on("shown.bs.select", function (e) {
            $(this).parent().find(".dropdown-menu.open").removeAttr("style");
            $(this).parent().find(".dropdown-menu.open > .inner.open").removeAttr("style");
            
            var liveSearch = $(this).parent().find(".dropdown-menu.open .bs-searchbox")[0];

            if (liveSearch) $(liveSearch).find("input").off("focus");
        });

        return;
    };
}(jQuery));
